import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from 'notistack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {getErrorMessageFromResponse} from "../common/helper";

const styles = theme => ({});

function DeleteFile (props) {

    const [showDialog, setShowDialog ] = useState(false);
    const [confirmed, setConfirmed] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState("")

    function closeDialog() {
        setConfirmed(false)
        setIsError(false)
        setError("");
        setIsFetching(false)
        setShowDialog(false)
    }

    function confirmDelete() {
        setShowDialog(true)
    }

    async function deleteFile() {

        setConfirmed(true);
        setIsFetching(true)

        const pathVar = "/" + props.boxDocID;
        const params =  "?userId=" + props.userDetails.boxId + '&audit=' + props.actionConfig.audit;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        const request = {
            method: 'DELETE',
            headers: {"Authorization": "Bearer " + props.userDetails.accessToken},
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

        await props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                if (response.ok) {
                    closeDialog()
                    enqueueSnackbar("Delete complete " + props.fileName, {variant: 'success'});
                    if(props.actionConfig.reloadWorkspaceAfterDelete && props.reloadWorkspace && props.selectedWorkspaceConfig){
                        props.reloadWorkspace(props.selectedWorkspaceConfig)
                    }
                    if (props.unmountFolderDocumentsGet){
                        props.unmountFolderDocumentsGet()
                    }

                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'deleting file'))
                        .then(message => {
                            setIsError(true);
                            setError(message )
                            enqueueSnackbar(message ,  {variant: 'error'})
                        })
                }

                setIsFetching(false)

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("delete Exception:", e, " url:", url, "request: ", request);
                enqueueSnackbar("Error, exception on delete", {variant: 'error'});
                closeDialog()
                setIsFetching(false)
            })
    }

    return (
        <React.Fragment>
            <div>
                <Tooltip title={isFetching ? "Deleting..." : "Delete"}>
                    <span> {/*necessary to have tooltip available on disabled button */}
                        <IconButton
                            aria-label = "Delete"
                            color={"secondary"}
                            aria-haspopup = "true"
                            onClick={confirmDelete}
                            disabled = { isFetching }
                            size="medium">
                            {
                                isFetching ?

                                    <CircularProgress color="secondary" size={20}/> :

                                    <i className='material-icons md-36'>
                                        delete
                                    </i>
                            }

                        </IconButton>
                    </span>
                </Tooltip>
            </div>

            {
                showDialog &&
                <Dialog
                    open={showDialog}
                    onClose={closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.fileName}
                        </DialogContentText>
                        {
                            isFetching &&
                            <LinearProgress variant={"indeterminate"} color={"primary"}/>
                        }
                        <Box width={400} sx={{typography: 'body1' , pt: (theme) => theme.spacing(2), pb: (theme) => theme.spacing(2)}} >
                            {
                                isFetching ? "Deleting..." :
                                    isError ? error :
                                "Are you sure you wish to delete this file?"
                            }
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        {
                            !isFetching &&
                            <Button color="grey" variant="contained" onClick={closeDialog}>
                                {confirmed ? "Close" : "Cancel"}
                            </Button>
                        }

                        {!isError &&
                            <Button color="secondary" variant="contained" onClick={deleteFile} autoFocus disabled={isFetching}>
                                {isFetching ? "Deleting..." : "Yes"}
                            </Button>
                        }

                    </DialogActions>
                </Dialog>
            }

        </React.Fragment>

    );
}

DeleteFile.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    reloadWorkspace: PropTypes.func,
    selectedWorkspaceConfig: PropTypes.object,
    fileName: PropTypes.string,
    unmountFolderDocumentsGet: PropTypes.func //if called from folder documents
};

export default (withStyles(styles)(DeleteFile));